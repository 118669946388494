import React from "react"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export default function ProjectDetails({ data }) {
  const {
    featuredImg,
    property_description,
    adress: { street },
  } = data.allCaHousesJson.nodes[0]
  return (
    <Layout>
      <div>
        <h2>{street}</h2>
        <div>
          <GatsbyImage image={getImage(featuredImg)} />
        </div>
        <div>
          <p>{property_description}</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HouseInfo($slug: String) {
    allCaHousesJson(filter: { id: { eq: $slug } }) {
      nodes {
        adress {
          city
          state
          street
          zip
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData
          }
        }
        property_description
      }
    }
  }
`
